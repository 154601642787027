import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {getSalesSpots} from "./actions";

export type SalesSpots = {
  bookings: any;
};

export type SalesSpotsState = {
  isPending: boolean;
  salesSpots: SalesSpots[];
};

const initialState: SalesSpotsState = {
  isPending: true,
  salesSpots: [],
};

const salesSpotsSlice = createSlice({
  name: "salesSpots",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSalesSpots.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(getSalesSpots.rejected, (state) => {
      state.isPending = false;
    });
    builder.addCase(getSalesSpots.fulfilled, (state, action: PayloadAction<SalesSpots[]>) => {
      state.salesSpots = action.payload;
      state.isPending = false;
    });
  },
});

export { salesSpotsSlice };
