import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

export { GridToolbarExport };

export const DataGridContainer = styled(DataGrid)({
  "@media print": {
    "@page": {
      size: "A4 landscape"
    },
  },
});

export const GridToolbarContainerExport = styled(GridToolbarContainer)({
  position: "absolute",
  top: "-33px",
  right: "0"
});

export const SalesBookingsContainer = styled("div")({});

export const PendingSpinnerContainer = styled("div")({
  justifyContent: "center",
  display: "flex",
  width: "100%",
});

export const PendingSpinner = styled(CircularProgress)({
  width: "100%",
  margin: "10px 0",
});

export const SalesListContainer = styled("div")({});
