import React, { memo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AccessEnum, AuthWrapper } from "../../components/auth-wrapper/auth-wrapper";

import { Wrapper } from "../wrapper/wrapper";

import { Sales } from "../../pages/sales/sales";
import { Business } from "../../pages/business/business";
import { Signup } from "../../pages/signup/signup";
import { Dashboard } from "../../pages/dashboard/dashboard";
import { SalesListings } from "../../pages/sales/sales-listings/sales-listings";
import { SalesBookings } from "../../pages/sales/sales-bookings/sales-bookings";
import { SalesOverview } from "../../pages/sales/sales-overview/sales-overview";
import { BusinessOverview } from "../../pages/business/business-overview/business-overview";
import {SalesCancellations} from "../../pages/sales/sales-cancellations/sales-cancellations";
import {SalesSpots} from "../../pages/sales/sales-spots/sales-spots";

export const Router = memo(() => (
  <BrowserRouter>
    <Wrapper>
      <Routes>
        <Route
          path="signup"
          element={
            <AuthWrapper redirectPath="/" access={AccessEnum.notAuthenticated}>
              <Signup />
            </AuthWrapper>
          }
        />
        <Route
          path="/"
          element={
            <AuthWrapper redirectPath="/signup" access={AccessEnum.authenticated}>
              <Dashboard />
            </AuthWrapper>
          }
        />
        <Route
          path="sales"
          element={
            <AuthWrapper redirectPath="/signup" access={AccessEnum.authenticated}>
              <Sales />
            </AuthWrapper>
          }
        >
          <Route path="" element={<SalesOverview />} />
          <Route path="listings" element={<SalesListings />} />
          <Route path="spots" element={<SalesSpots />} />
          <Route path="bookings" element={<SalesBookings />} />
          <Route path="cancellations" element={<SalesCancellations />} />
        </Route>
        <Route
          path="business"
          element={
            <AuthWrapper redirectPath="/signup" access={AccessEnum.authenticated}>
              <Business />
            </AuthWrapper>
          }
        >
          <Route path="" element={<BusinessOverview />} />
        </Route>
      </Routes>
    </Wrapper>
  </BrowserRouter>
));
