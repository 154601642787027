import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData, postData } from "../../../utils/api/api";

export const getSalesSpots = createAsyncThunk("salesSpots/getSalesSpots", async () => {
  return await getData("/spot");
});

export const getSalesVerified = createAsyncThunk("salesSpots/getSalesVerified", async (data: Record<string, unknown>) => {
  return await postData("/spot/verify", data);
});

export const getSalesApproved = createAsyncThunk("salesSpots/getSalesApproved", async (data: Record<string, unknown>) => {
  return await postData("/spot/approve", data);
});

export const getSalesDeclined = createAsyncThunk("salesSpots/getSalesDeclined", async (data: Record<string, unknown>) => {
  return await postData("/spot/decline", data);
});

