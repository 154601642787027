// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { memo, useEffect, useMemo, useState } from "react";

import {
  SalesBookingsContainer,
  PendingSpinnerContainer,
  PendingSpinner,
  SalesListContainer,
  DataGridContainer,
  GridToolbarContainerExport,
  GridToolbarExport,
} from "./components/styled";

import { useDispatch, useSelector } from "../../../store/hooks";
import { getSalesBookings } from "../../../store/modules/sales-bookings/actions";

export const SalesBookings = memo(() => {
  const [initialized, setInitialized] = useState<boolean>(false);

  const { salesBookings, isPending } = useSelector((state) => state.salesBookings);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSalesBookings());
    setInitialized(true);
  }, [dispatch]);

  const cols = [
    { field: "id", headerName: "ID"  },
    { field: "createdAt", headerName: "Booking", type: "date", flex: 0.3, minWidth: 78 },
    { field: "arrivalAt", headerName: "Arrival", type: "date", flex: 0.3, minWidth: 78 },
    { field: "status", headerName: "Status" },
    { field: "host", headerName: "Host" },
    { field: "country", headerName: "Listing country", width: 80 },
    { field: "country_guest", headerName: "Camper country", width: 80 },
    { field: "guest", headerName: "Guest" },
    { field: "numberOfGuests", headerName: "# Guests", width: 85 },
    { field: "numberOfNights", headerName: "# Nights", width: 80 },
    { field: "billedPrice", headerName: "$" },
    { field: "campspace", headerName: "Campspace2", flex: 1 },
    { field: "property_name", headerName: "Property Name", flex: 1 },
  ];

  const status = [
    "Requested",
    "Confirmed",
    "PendingPayment",
    "Paid",
    "Completed",
    "Declined",
    "Cancelled",
    "Payout",
  ];

  const sortedSalesBookings = useMemo(() => {
    if (salesBookings.length) {
      return salesBookings.slice().sort((a, b) => {
        if (a.created_at < b.created_at) {
          return -1;
        }
        return 1;
      });
    }

    return [];
  }, [salesBookings]);

  const rows = useMemo(() => {
    if (sortedSalesBookings.length) {
      return sortedSalesBookings.map((item) => {
        const { departure_at, arrival_at } = item;

        const diff = (new Date(departure_at).getTime() - new Date(arrival_at).getTime()) / 24 / 60 / 60 / 1000;

        return {
          id: item.id,
          createdAt: item.created_at && item.created_at.substring(0, 10),
          arrivalAt: item.arrival_at && item.arrival_at.substring(0, 10),
          status: status[item.status],
          country: item.country,
          country_guest: item.country_guest,
          host: item.email_host,
          guest: item.email_guest,
          numberOfGuests: item.guests,
          numberOfNights: diff,
          billedPrice: item.billed_price + " " + item.billed_currency,
          campspace: item.campspace_name,
          property_name: item.campsite_name
        };
      });
    }

    return [];
  }, [sortedSalesBookings, status]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainerExport>
        <GridToolbarExport printOptions={{}}/>
      </GridToolbarContainerExport>
    );
  };

  return (
    <SalesBookingsContainer>
      {isPending || !initialized ? (
        <PendingSpinnerContainer>
          <PendingSpinner />
        </PendingSpinnerContainer>
      ) : (
        <SalesListContainer>
          <DataGridContainer autoHeight rows={rows} columns={cols} components={{ Toolbar: CustomToolbar }}  />
        </SalesListContainer>
      )}
    </SalesBookingsContainer>
  );
});
