// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {memo, useEffect, useMemo, useState} from "react";
import moment from "moment";

import {
  SalesSpotsContainer,
  PendingSpinnerContainer,
  PendingSpinner,
  DataGridContainer,
  GridToolbarContainerExport,
  GridToolbarExport,
  Checkbox, StyledTextareaAutosize
} from "./components/styled";
import { useDispatch, useSelector } from "../../../store/hooks";
import {
  getSalesSpots, getSalesVerified, getSalesApproved, getSalesDeclined
} from "../../../store/modules/sales-spots/actions";
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  Tooltip,
  Typography,
  Box
} from "@mui/material";

export const SalesSpots = memo(() => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [updateRowData, setUpdateRowData] = useState<boolean>(false);
  const [declineRowId, setDeclineRowId] = useState<string>("");
  const [declineReason, setDeclineReason] = useState<string>("");
  const [declineReasonError, setDeclineReasonError] = useState<string>(false);
  const [openReasonModal, setOpenReasonModal] = useState<string>(false);

  const { salesSpots, isPending } = useSelector((state) => state.salesSpots);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSalesSpots());
    setInitialized(true);
  }, [dispatch]);

  const toggleApproved = (e, spot_id, approval_message) => {
    if (spot_id) {
      dispatch(getSalesApproved({id: spot_id, approval_message})).then(res => {
        if (res?.payload?.id) {
          const currentRow = rows.find(row => row.id === spot_id);
          currentRow.approval_status = "approved";
          setUpdateRowData(!updateRowData);
        }
      });
    }
  };

  const toggleDeclined = () => {
    if (declineRowId) {
      if (declineReason) {
        dispatch(getSalesDeclined({id: declineRowId, approval_message: declineReason})).then(res => {
          if (res?.payload?.id) {
            const currentRow = rows.find(row => row.id === declineRowId);
            currentRow.approval_status = "declined";
            currentRow.approval_message = declineReason;
            setDeclineRowId("");
            setOpenReasonModal(false);
            setDeclineReasonError(false)
          }
        });
      } else {
        setDeclineReasonError(true);
      }
    }
  };

  const toggleVerified = (e, spot_id) => {
    if (spot_id) {
      dispatch(getSalesVerified({id: spot_id, is_verified: e.target.checked}));
    }
  };

  const sortedSalesSpots = useMemo(() => {
    if (salesSpots && salesSpots?.length) {
      return salesSpots?.slice()?.sort((a, b) => {
        if (a.created_at < b.created_at) {
          return -1;
        }
        return 1;
      });
    }

    return [];
  }, [salesSpots]);

  const rows = useMemo(() => {
    if (sortedSalesSpots?.length) {
      return sortedSalesSpots.map((item) => ({
        id: item.id,
        createdAt: moment(item.created_at).format("DD-MM-YYYY"),
        name: item.name,
        country: item.location.country,
        is_verified: item.is_verified,
        approval_status: item.approval_status,
        decline_status: item.approval_status,
        approval_message: item.approval_message,
      }));
    }

    return [];
  }, [sortedSalesSpots]);

  const cols = useMemo(() => (
      [
        { field: "id", headerName: "ID", flex: 0.5 },
        { field: "createdAt", headerName: "Date", flex: 1, type: "date" },
        { field: "name", headerName: "Name", flex: 1, minWidth: 320 },
        { field: "country", headerName: "Country", flex: 1 },
        { field: "approval_message", headerName: "Reason", flex: 1, renderCell: (params) => {
            return (
                <Tooltip title={params.value}>
                  <Typography sx={{fontSize: 14}}>
                    {params.value.length > 10 ? (
                        params.value.slice(0, 10) + "..."
                    ) : (
                        params.value
                    )}
                  </Typography>
                </Tooltip>
            );
          }
        },
        { field: "is_verified", headerName: "Verified", flex: 1, type: "boolean", valueFormatter: ( params ) => params?.value ? "true" : "false", renderCell: (params) => {
            return <Checkbox
                onClick={(e) => e.stopPropagation()}
                defaultChecked={params?.value}
                onChange={(e) => toggleVerified(e, params?.row?.id)}
                disabled={!params?.row?.id} />;
          }
        },
        {
          field: "approval_status",
          headerName: "Approved",
          flex: 1,
          renderCell: (params) => {
            return <Checkbox
                onClick={(e) => e.stopPropagation()}
                defaultChecked={params?.value === "approved"}
                checked={params?.value === "approved"}
                onChange={(e) => toggleApproved(e, params?.row?.id, params?.row?.approval_message)}
                disabled={!params?.row?.id || params?.value === "approved"} />;
          },
        },
        {
          field: "decline_status",
          headerName: "Declined",
          flex: 1,
          // type: "boolean",
          // valueFormatter: ( params ) => params?.value === "desclined" ? "true" : "false",
          renderCell: (params) => {
            return <Checkbox
                onClick={(e) => e.stopPropagation()}
                defaultChecked={params?.row?.approval_status === "declined"}
                checked={params?.row?.approval_status === "declined"}
                onChange={(e) => {
                  setDeclineRowId(params?.row?.id);
                  setOpenReasonModal(true);
                  setDeclineReason(params?.row?.approval_message || "")
                }}
                disabled={!params?.row?.id || params?.row?.approval_status === "declined"} />;
          },
        },
      ]
  ), [rows, updateRowData]);

  useEffect(() => {
    if (!openReasonModal) {
      setDeclineRowId("");
    }
  }, [openReasonModal])

  const CustomToolbar = () => {
    return (
      <GridToolbarContainerExport>
        <GridToolbarExport printOptions={{}}/>
      </GridToolbarContainerExport>
    );
  };

  return (
    <SalesSpotsContainer>
      {isPending || !initialized ? (
        <PendingSpinnerContainer>
          <PendingSpinner />
        </PendingSpinnerContainer>
      ) : (
        <SalesSpotsContainer>
          <DataGridContainer
            autoHeight
            rows={rows}
            columns={cols}
            components={{ Toolbar: CustomToolbar }}
            // onCellClick={(a) => {
            //   const origin = window.location.host === "missioncontrol.campanyon.com" ? externalLinks.campanyon : externalLinks.campanyonDev;
            //   window
            //     .open(
            //         origin + "/en/listing/_/_/" + a.row.campsiteUrl + "/" + a.row.campspaceUrl + "?back=0",
            //       "_blank",
            //     )
            //     .focus();
            // }}
          />
        </SalesSpotsContainer>
      )}

      <DeclineModal
          declineReason={declineReason}
          declineReasonError={declineReasonError}
          setDeclineReason={setDeclineReason}
          open={openReasonModal}
          setOpenReasonModal={setOpenReasonModal}
          toggleDeclined={toggleDeclined}
      />
    </SalesSpotsContainer>
  );
});


const DeclineModal = ({open, setOpenReasonModal, declineReasonError, toggleDeclined, declineReason, setDeclineReason}) => {
  return (
      <Dialog
          open={open}
          onClose={() => setOpenReasonModal(false)}
      >
        <DialogTitle>Reason for decline</DialogTitle>

        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            Please describe why the spot has been declined, ideally in the language of the user who added the spot. The reason for decline will be shared with the user
          </Typography>

          <StyledTextareaAutosize
              minRows={10}
              value={declineReason}
              onChange={(e) => setDeclineReason(e.target.value)}
          />

          {declineReasonError && (
            <Typography style={{color: "#ff4d4f", fontSize: 14}}>
              This field cannot be empty
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button
              variant="outlined"
              onClick={() => setOpenReasonModal(false)}
          >
            Cancel
          </Button>
          <Button
              variant="contained"
              sx={{ml: 1}}
              onClick={() => toggleDeclined()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
  )
}
