import { DataGrid, GridToolbarContainer, GridToolbarExport  } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import MuiCheckbox from "@mui/material/Checkbox/Checkbox";
import {TextareaAutosize} from "@mui/material";

export { GridToolbarExport };

export const GridToolbarContainerExport = styled(GridToolbarContainer)({
  position: "absolute",
  top: "-33px",
  right: "0"
});

export const DataGridContainer = styled(DataGrid)({
  "@media print": {
    "@page": {
      size: "A4 landscape"
    },
  },
});

export const Checkbox = styled(MuiCheckbox)(({ theme }) => ({
  width: "100%",
  color: theme.palette.secondary.main,
  "&.Mui-checked": {
    color: theme.palette.primary.main,
  },
  "&.MuiCheckbox-root": {
    background: "transparent !important"
  },
}));

export const SalesSpotsContainer = styled("div")({});

export const PendingSpinnerContainer = styled("div")({
  justifyContent: "center",
  display: "flex",
  width: "100%",
});

export const PendingSpinner = styled(CircularProgress)({
  width: "100%",
  margin: "10px 0",
});

export const StyledTextareaAutosize = styled(TextareaAutosize)({
  resize: "vertical",
  width: "100%",
  borderRadius: 3,
  "&:focus": {
    outline: "none !important",
    border: "2px solid #F16335",
  }
});
