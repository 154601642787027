// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { memo, useEffect, useMemo, useState } from "react";

import {
  SalesListingsContainer,
  PendingSpinnerContainer,
  PendingSpinner,
  SalesListContainer,
  DataGridContainer,
  GridToolbarContainerExport,
  GridToolbarExport,
  Checkbox,
  MuiTooltip,
} from "./components/styled";

import { useDispatch, useSelector } from "../../../store/hooks";
import {
  getSalesCampspaces,
  getSalesPromoted,
  getSalesEpic,
  getVerified
} from "../../../store/modules/sales-listings/actions";
import {externalLinks} from "../../../utils/configs/external-links";

export const SalesListings = memo(() => {
  const [initialized, setInitialized] = useState<boolean>(false);

  const { salesCampspaces, isPending } = useSelector((state) => state.salesListings);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSalesCampspaces());
    setInitialized(true);
  }, [dispatch]);

  const togglePromoted = (e, camp_space_id) => {
    if (camp_space_id) {
      dispatch(getSalesPromoted({camp_space_id: camp_space_id, value: e.target.checked}));
    }
  };

  const toggleEpicToilet = (e, camp_space_id) => {
    if (camp_space_id) {
      dispatch(getSalesEpic({camp_space_id: camp_space_id, value: e.target.checked}));
    }
  };

  const toggleVerified = (e, camp_space_id) => {
    if (camp_space_id) {
      dispatch(getVerified({camp_space_id: camp_space_id, value: e.target.checked}));
    }
  };

  const cols = [
    { field: "id", headerName: "ID", flex: 0.5, renderCell: (params: any) => (
        <MuiTooltip title={params.row.id} >
          <div className="MuiDataGrid-cellContent">{params.row.id}</div>
        </MuiTooltip>
       ), 
    },
    { field: "hostCreatedAt", headerName: "Registration Date", flex: 1, type: "date", renderCell: (params: any) => (
        <MuiTooltip title={params.row.hostCreatedAt} >
          <div className="MuiDataGrid-cellContent">{params.row.hostCreatedAt}</div>
        </MuiTooltip>
      ),  
    },
    { field: "createdAt", headerName: "Date", flex: 1, type: "date", renderCell: (params: any) => (
        <MuiTooltip title={params.row.createdAt} >
          <div className="MuiDataGrid-cellContent">{params.row.createdAt}</div>
        </MuiTooltip>
      ), 
    },
    { field: "name", headerName: "Listing Name", flex: 1, minWidth: 200, renderCell: (params: any) => (
        <MuiTooltip title={params.row.name} >
          <div className="MuiDataGrid-cellContent">{params.row.name}</div>
        </MuiTooltip>
      ), 
    },
    { field: "property_name", headerName: "Property Name", flex: 1, minWidth: 200, renderCell: (params: any) => (
        <MuiTooltip title={params.row.property_name} >
          <div className="MuiDataGrid-cellContent">{params.row.property_name}</div>
        </MuiTooltip>
      ), 
    },
    { field: "property_category", headerName: "Category", flex: 1, minWidth: 60, renderCell: (params: any) => (
        <MuiTooltip title={params.row.property_category} >
          <div className="MuiDataGrid-cellContent">{params.row.property_category}</div>
        </MuiTooltip>
      ), 
    },
    { field: "property_subcategory", headerName: "Subcategory", flex: 1, minWidth: 60, renderCell: (params: any) => (
        <MuiTooltip title={params.row.property_subcategory} >
          <div className="MuiDataGrid-cellContent">{params.row.property_subcategory}</div>
        </MuiTooltip>
      ), 
    },
    { field: "country", headerName: "Country", flex: 1 },
    { field: "isPublic", headerName: "Is public", flex: 1, type: "boolean", valueFormatter: ( params ) => params?.value ? "Yes" : "No",  renderCell: (params) => {
      return params?.value ? "Yes" : "No";
    }
    },
    { field: "isAvailable", headerName: "Is available", flex: 1, type: "boolean", valueFormatter: ( params ) => params?.value ? "Yes" : "No",  renderCell: (params) => {
      return params?.value ? "Yes" : "No";
    }
    },
    { field: "campspaceUrl", hide: true },
    { field: "campsiteUrl", hide: true },
    { field: "promoted", headerName: "Promoted", flex: 1, type: "boolean", valueFormatter: ( params ) => params?.value ? "true" : "false", renderCell: (params) => {
      return <Checkbox
        onClick={(e) => e.stopPropagation()}
        defaultChecked={params?.value}
        onChange={(e) => togglePromoted(e, params?.row?.id)}
        disabled={!params?.row?.id} />;
    },
    },
    { field: "epicToilet", headerName: "Epic toilet", flex: 1, type: "boolean", valueFormatter: ( params ) => params?.value ? "true" : "false", renderCell: (params) => {
      return <Checkbox
        onClick={(e) => e.stopPropagation()}
        defaultChecked={params?.value}
        onChange={(e) => toggleEpicToilet(e, params?.row?.id)}
        disabled={!params?.row?.id} />;
    },
    },
    { field: "verified", headerName: "Verified", flex: 1, type: "boolean", valueFormatter: ( params ) => params?.value ? "true" : "false", renderCell: (params) => {
        return <Checkbox
            onClick={(e) => e.stopPropagation()}
            defaultChecked={params?.value}
            onChange={(e) => toggleVerified(e, params?.row?.id)}
            disabled={!params?.row?.id} />;
      },


    },
  ];

  const sortedSalesCampspaces = useMemo(() => {
    if (salesCampspaces && salesCampspaces?.length) {
      return salesCampspaces?.slice()?.sort((a, b) => {
        if (a.campspace.created_at < b.campspace.created_at) {
          return -1;
        }
        return 1;
      });
    }

    return [];
  }, [salesCampspaces]);

  const rows = useMemo(() => {
    if (sortedSalesCampspaces?.length) {
      return sortedSalesCampspaces.map((item) => ({
        id: item.campspace.id,
        hostCreatedAt: item.campspace.host_created_at.substring(0, 10),
        createdAt: item.campspace.created_at.substring(0, 10),
        name: item.campspace.name,
        property_name: item.campsite.name,
        property_category: item.campsite?.type || "",
        property_subcategory: (item.campspace?.type  || "").replace(/_/g, " "),
        country: item.campspace.country,
        isPublic: item.campspace.is_public,
        isAvailable: item.campspace.is_available,
        campspaceUrl: item.campspace.url_name,
        campsiteUrl: item.campsite.url_name,
        promoted: item.campspace.is_promoted,
        epicToilet: item.campspace.is_epic,
        verified: item.campspace.is_verified,
      }));
    }

    return [];
  }, [sortedSalesCampspaces]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainerExport>
        <GridToolbarExport printOptions={{}}/>
      </GridToolbarContainerExport>
    );
  };

  return (
    <SalesListingsContainer>
      {isPending || !initialized ? (
        <PendingSpinnerContainer>
          <PendingSpinner />
        </PendingSpinnerContainer>
      ) : (
        <SalesListContainer>
          <DataGridContainer
            autoHeight
            rows={rows}
            columns={cols}
            components={{ Toolbar: CustomToolbar }}
            onCellClick={(a) => {
              const origin = window.location.host === "missioncontrol.campanyon.com" ? externalLinks.campanyon : externalLinks.campanyonDev;
              window
                .open(
                    origin + "/en/listing/_/_/" + a.row.campsiteUrl + "/" + a.row.campspaceUrl + "?back=0",
                  "_blank",
                )
                .focus();
            }}
          />
        </SalesListContainer>
      )}
    </SalesListingsContainer>
  );
});
