import { CombinedState } from "redux";
import { configureStore, combineReducers, Action } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authSlice from "./modules/auth/slice";
import dashboardSlice from "./modules/dashboard/slice";
import salesBookingsSlice from "./modules/sales-bookings/slice";
import salesCancellationsSlice from "./modules/sales-cancellations/slice";
import {
  salesListingsSlice,
  salesListingsPromoted,
  salesListingsEpic,
  salesListingsVerified
} from "./modules/sales-listings/slice";
import { salesHostsByDaySlice, salesListingsByDaySlice, salesListingsByAvailability, salesListingsPublic } from "./modules/sales-overview/slice";
import businessSlice from "./modules/business/slice";
import {salesSpotsSlice} from "./modules/sales-spots/slice";

const persistConfig = {
  key: "root",
  storage,
};

const appReducer = combineReducers({
  auth: authSlice.reducer,
  dashboard: dashboardSlice.reducer,
  salesBookings: salesBookingsSlice.reducer,
  salesSpots: salesSpotsSlice.reducer,
  salesCancellations: salesCancellationsSlice.reducer,
  salesListings: salesListingsSlice.reducer,
  salesListingsPromoted: salesListingsPromoted.reducer,
  salesListingsEpic: salesListingsEpic.reducer,
  salesListingsVerified: salesListingsVerified.reducer,
  salesHostsByDay: salesHostsByDaySlice.reducer,
  salesListingsByDay: salesListingsByDaySlice.reducer,
  salesListingsAvailability: salesListingsByAvailability.reducer,
  salesListingsPublic: salesListingsPublic.reducer,
  business: businessSlice.reducer,
});

const rootReducer = (state: CombinedState<any>, action: Action) => {
  if (action.type === "CLEAR_DATA") {
    storage.removeItem("persist:root");

    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistedStore = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
